import * as Turbo from '@hotwired/turbo'
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers";
import ahoy from "ahoy.js"
const application = Application.start()
const components = import.meta.glob("@components/**/*.js", { eager: true })
registerControllers(application, components)


const controllers = import.meta.glob("@/controllers/*_controller.js", { eager: true })
registerControllers(application, controllers)

application.debug = true
window.Stimulus   = application

Turbo.start()
ahoy.track("turbo start", {language: "JavaScript"});

export { application }

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
